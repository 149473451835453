export const GOODS_LABEL = 'GOODS_LABEL';
export const INTERNAL_ORDER_NUMBER = 'INTERNAL_ORDER_NUMBER';
export const COMMENT = 'COMMENT';
export const TEMPORARY_ADDRESSES = 'TEMPORARY_ADDRESSES';

export const EDIT_DELIVERY_ADDRESS = 'EDIT_DELIVERY_ADDRESS';

export const DELIVERY_PREFERENCE = {
  SPLIT: 'SPLIT',
  SINGLE_DELIVERY: 'SINGLE_DELIVERY',
  USER_DEFINED: 'USER_DEFINED',
};

export const PAYMENT_METHODS = {
  INVOICE: 'Invoice',
  PAYPAL: 'PayPalExternal',
};

export const PAYPAL_ENDPOINT = '/distancify/api/paypal';

export const PREVIOUS_URL = 'PREVIOUS_URL';

export const ERRORS = {
  NOT_ENOUGH_STOCK: 'NOT_ENOUGH_STOCK',
  NO_DELIVERY_ADDRESS: 'NO_DELIVERY_ADDRESS',
  NO_CONTACT_PERSON: 'NO_CONTACT_PERSON',
  PAY_PAL_VALIDATION_ERROR: 'PAY_PAL_VALIDATION_ERROR',
  PAY_PAL_AUTHORIZATION_ERROR: 'PAY_PAL_AUTHORIZATION_ERROR',
  NO_DELIVERY_COUNTRY: 'NO_DELIVERY_COUNTRY',
};