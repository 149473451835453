import useContext from '@/composables/useContext';
import useCartItem from '@/composables/useCartItem';
import useStock from '@/composables/useStock';

export default function () {
  const { mitt } = useContext();
  const { setSelectedRow } = useCartItem();
  const { getAllStockItems } = useStock();

  function openEditQuantityModal(cartRow) {
    const row = {
      packQuantity: cartRow.packQuantity || 1,
      productName: cartRow.variant.displayName || cartRow.variant.variantName || '',
      images: cartRow.variant.images,
      quantity: cartRow.quantity,
      stockItems: getAllStockItems({
        variant: cartRow,
        quantity: cartRow.quantity,
        subtractQuantityFromCart: true,
      }),
      totalStockAvailable: cartRow?.stock?.totalStockAvailable || 0,
      articleNumber: cartRow.articleNumber,
      rowId: cartRow.id,
      isFreeGift: cartRow.isFreeGift,
    };
    setSelectedRow(row);
    mitt.emit('SetRowQuantity:Open');
  }

  return {
    openEditQuantityModal,
  };
}
