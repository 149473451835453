import { inject, InjectionKey, provide, ref, Ref, readonly } from 'vue';

export const selectedRowKey: InjectionKey<Ref<any>> = Symbol('selectedRow');
export const setSelectedRowKey: InjectionKey<(newRow: any) => void> =
  Symbol('setSelectedRow');

export default function () {
  const selectedRow = ref();

  provide(selectedRowKey, selectedRow);
  provide(setSelectedRowKey, setSelectedRow);

  function setSelectedRow(newRow) {
    selectedRow.value = newRow;
  }

  return {
    selectedRow: readonly(selectedRow),
    setSelectedRow,
  };
}

export function getSelectedRowContext() {
  return {
    selectedRow: inject(selectedRowKey, ref(<any>{})),
    //setSelectedRow: inject(setSelectedRowKey, (newRow: any) => {}),
  };
}
