import { isSameDay } from 'date-fns';

import useContext from './useContext';
import useFormatting from './useFormatting';
import useWebsiteTexts from './useWebsiteTexts';

export default function () {
  const { cart } = useContext();
  const { websiteText } = useWebsiteTexts();
  const { formatDate } = useFormatting();

  const getCurrentStock = (variant: any) => ({
    ...(variant?.stock?.currentStock || {}),
    stockLabel: websiteText('global__in_stock').value,
    deliveryLabel: websiteText('global__available_today').value,
    physicalStock: true,
  });

  const getIncomingStockItems = (variant: any) => {
    const incomingStock = [...(variant?.stock?.incomingStock || [])];
    return incomingStock.map((s: any) => {
      const date = new Date(s.date);
      const o = { ...s };
      o.stockLabel = formatDate(date, 'yyyy-MM-dd');
      o.deliveryLabel = websiteText('global__available_date', {
        date: o.stockLabel,
      }).value;
      o.incomingStock = true;
      return o;
    });
  };

  const getAllStockItems = ({ variant, quantity, subtractQuantityFromCart }: any) => {
    const stockItems = [...getIncomingStockItems(variant)] || [];
    const currentStock = getCurrentStock(variant);
    if (stockItems.length > 0) {
      stockItems.sort((a, b) => a.amount - b.amount);
      stockItems.forEach((s) => (s.isActive = false));
    }
    if (currentStock) {
      stockItems.unshift(currentStock);
    }

    if (subtractQuantityFromCart) {
      const cartAmount = getCartStockAmount(variant);

      if (cartAmount > 0) {
        stockItems.forEach((s) => {
          s.amount -= cartAmount;
          if (s.amount < 0) {
            s.amount = 0;
          }
        });
      }
    }

    stockItems.some((s) => {
      if (s.amount >= (quantity || variant?.quantity || 1)) {
        return (s.isActive = true);
      }
    });

    return stockItems;
  };

  const getActiveStockItem = (variant: any, quantity = null) => {
    const stockItems = (quantity ? getAllStockItems({ variant, quantity }) : getAllStockItems({ variant })) || [];
    return stockItems?.find((s) => s.isActive);
  };

  const getStockStatusText = (variant: any, hideInStock = false) => {
    const activeStockItem = getActiveStockItem(variant);
    if (!activeStockItem) return '';
    if (activeStockItem.physicalStock) {
      return !hideInStock ? websiteText('global__in_stock').value : '';
    }
    if (activeStockItem.deliveryLabel) {
      return activeStockItem.deliveryLabel;
    }
    if (activeStockItem.deliveryDate) {
      const label = activeStockItem.deliveryDate;
      return websiteText('checkout__available_date', {
        date: label || '',
      }).value;
    }
    return '';
  };

  const getStockAvailable = ({ variant, stockItem, subtractQuantityFromCart }) => {
    let stock = stockItem?.amount || 0;
    if (subtractQuantityFromCart) {
      const cartAmount = getCartStockAmount(variant);

      stock -= cartAmount;
    }
    if (stock < 0) {
      stock = 0;
    }

    return stock;
  };

  const getTotalStockAvailable = ({ variant, subtractQuantityFromCart }) => {
    let stock = variant.stock?.totalStockAvailable || 0;
    if (subtractQuantityFromCart) {
      const cartAmount = getCartStockAmount(variant);
      stock -= cartAmount;
    }
    if (stock < 0) {
      stock = 0;
    }

    return stock;
  };

  const getEstimatedShippingDateTimeLabel = (variant: any, quantity = null) => {
    const today = new Date();
    const activeStockItem = getActiveStockItem(variant, quantity);
    let estimatedShippingDate = activeStockItem?.deliveryDate;

    if (!estimatedShippingDate) {
      estimatedShippingDate = variant?.stock?.expectedShippingDateTime
        ? new Date(activeStockItem.date || variant.stock.expectedShippingDateTime)
        : today;

      if (isSameDay(today, estimatedShippingDate)) {
        return websiteText('global__available_today').value;
      }

      return websiteText('checkout__available_date', {
        date: estimatedShippingDate ? `${formatDate(new Date(estimatedShippingDate), 'yyyy-MM-dd')}` : '',
      }).value;
    }
    return websiteText('checkout__available_date', {
      date: estimatedShippingDate,
    }).value;
  };

  function getCartStockAmount(variant: any) {
    const cartAmount = (cart.value?.rows || [])
      .filter((e) => e.articleNumber == variant?.id || e.articleNumber == variant?.articleNumber)
      .reduce((sum, e) => {
        sum += e.quantity;
        return sum;
      }, 0);
    return cartAmount;
  }

  return {
    getAllStockItems,
    getIncomingStockItems,
    getActiveStockItem,
    getStockStatusText,
    getEstimatedShippingDateTimeLabel,
    getTotalStockAvailable,
    getStockAvailable,
  };
}
