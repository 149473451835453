<script setup>
import { inject, computed, ref, watch, nextTick } from 'vue';
import { cartKey, emitterKey, isWholesaleCartOpenKey } from '@/keys';

import useContext from '@/composables/useContext';
import useUser from '@/composables/useUser';

import HamburgerMenu from './HamburgerMenu.vue';

const cart = inject(cartKey, { value: {} });
const mitt = inject(emitterKey);
const { settings, channel, navigate, resolveLink } = useContext();
const { isAuthenticated } = useUser();

const isWholesaleCartOpen = inject(isWholesaleCartOpenKey, ref(false));
const cartFocusReturn = ref(null);

watch(isWholesaleCartOpen, (val) => {
  if (!val) {
    setFocus();
  }
});

async function setFocus() {
  await nextTick();
  cartFocusReturn.value?.focus();
}

const cartCount = computed(() =>
  (cart.value?.rows || []).reduce((acc, c) => acc + c.quantity, 0)
);

function openQuickCart() {
  mitt.emit('openQuickCart');
}

const myPagesLink = computed(() => {
  return resolveLink(() => settings.value?.myPagesLink)?.url || ''
})

const showMyPagesIcon = computed(() => {
  return isAuthenticated.value && myPagesLink.value;
});

async function navigateToMyPagesPage() {
  await navigate(myPagesLink.value);
}
</script>

<template>
  <div class="header-actions">
    <div
      class="header-actions__item"
      v-if="showMyPagesIcon"
      @click="navigateToMyPagesPage"
    >
      <div class="header-actions__item-icon">
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0002 16.9417V15.1769C15.0002 14.2407 14.6268 13.343 13.9622 12.681C13.2976 12.0191 12.3962 11.6472 11.4562 11.6472H4.36822C3.42829 11.6472 2.52686 12.0191 1.86223 12.681C1.1976 13.343 0.824219 14.2407 0.824219 15.1769V16.9417"
            stroke="black"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.91119 8.11763C9.86849 8.11763 11.4552 6.53735 11.4552 4.58799C11.4552 2.63862 9.86849 1.05835 7.91119 1.05835C5.95389 1.05835 4.36719 2.63862 4.36719 4.58799C4.36719 6.53735 5.95389 8.11763 7.91119 8.11763Z"
            stroke="black"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="header-actions__item-label" tabindex="0">
        {{ $globalTexts.global__my_pages_label || 'global__my_pages_label' }}
      </div>
    </div>
    <div
      class="header-actions__item"
      @click="openQuickCart"
      @keypress.enter="openQuickCart"
      v-if="!isWholesaleCartOpen"
    >
      <div class="header-actions__item-icon">
        <svg
          width="18"
          height="21"
          viewBox="0 0 18 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.625"
            y="7.14258"
            width="16.75"
            height="12.8374"
            stroke="black"
            stroke-width="1.25"
          />
          <path
            d="M14.1404 9.71939V6.51771C14.1404 3.68852 11.8378 1.395 8.9975 1.395C6.15718 1.395 3.85464 3.68852 3.85464 6.51771V9.71939"
            stroke="black"
            stroke-width="1.25"
            stroke-linecap="round"
          />
        </svg>
        <div class="header-actions__cart-count" v-if="cartCount > 0">
          {{ cartCount }}
        </div>
      </div>
      <div class="header-actions__item-label" ref="cartFocusReturn" tabindex="0">
        {{ $globalTexts.global__cart_label || 'global__cart_label' }}
      </div>
    </div>
    <HamburgerMenu />
  </div>
</template>

<style>
.header-actions {
  display: flex;

  @media (min-width: 1280px) {
    padding-right: 30px;
  }
}

.header-actions__item {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header-actions__item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  position: relative;
}

.header-actions__cart-count {
  background-color: var(--color-main-black);
  color: var(--color-neutrals-00);
  width: 18px;
  height: 18px;
  border-radius: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 10px;

  position: absolute;
  top: -5px;
  right: 1px;
}

.header-actions__item-label {
  white-space: nowrap;
  @media (max-width: 767px) {
    display: none;
  }
}
</style>
