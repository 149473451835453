import createApp from './main'
import { EnvironmentOptions } from './EnvironmentOptions'
import StandardLayout from './layouts/WholesaleLayout.vue'
import AddToCart from './components/pdp/WholesaleAddToCart'
import StockStatus from './components/pdp/WholesaleStockStatus.vue'
import HeaderActions from './components/SiteHeader/WholesaleHeaderActions.vue'
import ShoppingPreferences from './components/ShoppingPreferences'

export default function (
  environmentOptions: EnvironmentOptions,
  workerFactory?: () => Worker,
) {
  const app = createApp(
    environmentOptions,
    {
      StandardLayout,
      AddToCart,
      HeaderActions,
      StockStatus,
      ShoppingPreferences,
    },
    workerFactory,
  )

  return app
}