import axios from 'axios';
import { ref, inject } from 'vue';
import { getItemKey } from '@drapejs/core';
import { environmentKey, EnvironmentOptions } from '@/EnvironmentOptions';
import useWebsiteTexts from './useWebsiteTexts';

export default function () {
  const getItem = inject(getItemKey, async () => {});
  const env = inject(environmentKey, <EnvironmentOptions>{});

  const { websiteText } = useWebsiteTexts();

  const requestPending = ref(false);

  async function createQuote({ title, description, fileName }) {
    try {
      requestPending.value = true;

      const litium = await getItem('__litium');
      const response = await axios({
        method: 'POST',
        url: `${env.litiumBaseUrl}/download-quote/pdf-content`,
        headers: {
          'LH-Session': litium.session,
          'LH-Uid': litium.uid,
          'LH-Cart': litium.cart,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          title,
          description,
          fileName,
        }),
        responseType: 'blob',
      });

      if (response.status != 200) {
        throw Error(response.statusText);
      }

      return response.data;
    } catch (err) {
      throw websiteText('quotes__generate_quote_error').value;
    } finally {
      requestPending.value = false;
    }
  }

  return {
    createQuote,
    requestPending,
  };
}
