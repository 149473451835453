import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { request, query, sales } from '@distancify/drapejs-litium';
import { gql } from 'graphql-request';

export const commands = {
  fetchBaseProduct: defineCommand<{
    url: string;
    articleNumber: string;
    categoryId: string;
    cacheKey?: string;
  }>('fetchBaseProduct'),
  addBackInStockAlert: defineCommand<{
    url: string;
    articleNumber: string;
    email: string;
    cacheKey?: string;
  }>('addBackInStockAlert'),
  applyVoucherCode: defineCommand<{
    url: string;
    voucherCode: string;
    cacheKey?: string;
  }>('applyVoucherCode'),
  setAddressFromKlarna: defineCommand<{
    url: string;
    country: string;
    zip: string;
    email: string;
    cacheKey?: string;
  }>('setAddressFromKlarna'),
  getKlarnaOrder: defineCommand<{
    url: string;
    transactionNumber: string;
    accountId: string;
    cacheKey?: string;
  }>('getKlarnaOrder'),
  setGaTransactionReportAndClearCart: defineCommand<{
    url: string;
    transactionNumber: string;
    accountId: string;
    cacheKey?: string;
  }>('setGaTransactionReportAndClearCart'),
  addToCartMultiple: defineCommand<{
    items: [
      {
        articleNumber: string;
        quantity: number;
      }
    ];
  }>('addToCartMultiple')
};

export const receivers = {
  fetchBaseProduct: defineReceiver(
    commands.fetchBaseProduct,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query fetchBaseProduct($url: String!, $articleNumber: String!, $categoryId: String) {
            session(url: $url) {
              baseProduct(id: $articleNumber) {
                id
                name
                video {
                  videoId
                  fileName
                }
                displayName
                categoryBreadCrumbs(categoryId: $categoryId)
                concept
                productTag {
                  tagName
                  id
                }
                certifications
                uSPs
                fieldsJson
                variants {
                  id
                  systemId
                  color
                  displayName
                  concept
                  url(categoryId: $categoryId)
                  price {
                    campaignPrice {
                      priceWithVat
                    }
                    listPrice {
                      priceWithVat
                    }
                  }
                  stock(quantity: 1) {
                    totalStockAvailable
                    expectedShippingDateTime
                    currentStock {
                      amount
                      date
                    }
                    incomingStock {
                      amount
                      date
                    }
                  }
                  images {
                    imageId
                    fileName
                    alt
                    dimension {
                      width
                      height
                      isEmpty
                    }
                  }
                }
              }
            }
          }
        `),
        {
          url: command.url,
          articleNumber: command.articleNumber,
          categoryId: command.categoryId,
        }
      );

      if (command.cacheKey) {
        await this.cache.setItem(command.cacheKey, result.session.baseProduct);
      }

      return result.session.baseProduct;
    },
    'litium'
  ),
  addBackInStockAlert: defineReceiver(
    commands.addBackInStockAlert,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          mutation addBackInStockAlert($url: String!, $variantId: String!, $email: String!) {
            session(url: $url) {
              addBackInStockAlert(variantId: $variantId, email: $email)
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          email: command.email,
          variantId: command.articleNumber,
        }
      );

      return { error: result.session.addBackInStockAlert };
    },
    'litium'
  ),
  applyVoucherCode: defineReceiver(
    commands.applyVoucherCode,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation applyVoucherCode($url: String!, $discountCode: String!) {
              session(url: $url) {
                setDiscountCode(discountCode: $discountCode) {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          discountCode: command.voucherCode || '',
        }
      );

      await this.cache.setItem('__cart', result.session.setDiscountCode.cart);

      return result.session.setDiscountCode;
    },
    'litium'
  ),
  setAddressFromKlarna: defineReceiver(
    commands.setAddressFromKlarna,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setAddressFromKlarna($url: String!, $country: String!, $zip: String!, $email: String!) {
              session(url: $url) {
                setAddressFromKlarna(country: $country, zip: $zip, email: $email) {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          country: command.country || '',
          zip: command.zip || '',
          email: command.email || '',
        }
      );

      await this.cache.setItem('__cart', result.session.setAddressFromKlarna.cart);

      return result.session.setAddressFromKlarna;
    },
    'litium'
  ),
  getKlarnaOrder: defineReceiver(
    commands.getKlarnaOrder,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            query getKlarnaOrder($url: String!, $transactionNumber: String!, $accountId: String!) {
              session(url: $url) {
                klarnaReceipt(transactionNumber: $transactionNumber, accountId: $accountId) {
                  htmlSnippet
                  receipt {
                    totalDeliveryCost
                    totalDiscount
                    totalOrderRow
                    totalVat
                    grandTotal
                    paymentMethod
                    externalOrderId
                    cartOrderId
                    currency {
                      id
                    }
                    rows {
                      ...RowFields
                    }
                  }
                }
              }
            }
          `,
          sales.withRowFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          transactionNumber: command.transactionNumber || '',
          accountId: command.accountId || '',
        }
      );

      return result.session.klarnaReceipt;
    },
    'litium'
  ),
  setGaTransactionReportAndClearCart: defineReceiver(
    commands.setGaTransactionReportAndClearCart,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setGaTransactionReportAndClearCart(
              $url: String!
              $transactionNumber: String!
              $accountId: String!
            ) {
              session(url: $url) {
                setGaTransactionReportAndClearCart(transactionNumber: $transactionNumber, accountId: $accountId) {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          transactionNumber: command.transactionNumber || '',
          accountId: command.accountId || '',
        }
      );
      await this.cache.setItem('__cart', result.session.setGaTransactionReportAndClearCart.cart);
      return result.session.setGaTransactionReportAndClearCart;
    },
    'litium'
  ),
  addToCartMultiple: defineReceiver(
    commands.addToCartMultiple,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation addToCartMultiple($url: String!, $items: [AddToCartRequest]) {
              session(url: $url) {
                addToCartMultiple(items: $items) {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          items: command.items || [],
        }
      );

      await this.cache.setItem('__cart', result.session.addToCartMultiple.cart);

      return result.session.addToCartMultiple;
    },
    'litium'
  )
};
