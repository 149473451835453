import { onMounted, onUnmounted } from 'vue';
import useContext from '@/composables/useContext';

export default function () {
  const { mitt } = useContext();

  function useEventListener(target: any, event: any, callback: any) {
    onMounted(() => target.addEventListener(event, callback));
    onUnmounted(() => target.removeEventListener(event, callback));
  }

  function useWindowEventListener(
    event: any,
    callback: any,
    executeOnMounted: boolean,
    args?: any
  ) {
    onMounted(() => {
      if (executeOnMounted) {
        args ? callback(args) : callback();
      }
      window.addEventListener(event, callback);
    });
    onUnmounted(() => window.removeEventListener(event, callback));
  }

  function useEmitterEventListener(event: any, callback: any) {
    onMounted(() => mitt.on(event, callback));
    onUnmounted(() => mitt.off(event, callback));
  }

  return {
    useEventListener,
    useWindowEventListener,
    useEmitterEventListener,
  };
}
