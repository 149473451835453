import useContext from '@/composables/useContext';

import { PREVIOUS_URL } from '@/composables/WholesaleCheckout/constants';

import { buildUrlPathWithQuery } from "@/utils"

export default function () {
  const { route, channel, navigate } = useContext();

  function getPreviousUrl() {
    return sessionStorage.getItem(PREVIOUS_URL) || channel.value?.rootPath || '';
  }

  function setCurrentUrl() {
    sessionStorage.setItem(PREVIOUS_URL, buildUrlPathWithQuery(route.pathname, route.query));
  }

  function navigateBack() {
    navigate(getPreviousUrl());
  }

  return {
    setCurrentUrl,
    navigateBack,
  };
}
