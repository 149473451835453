import { ref, computed, watch, provide, InjectionKey, Ref, inject } from 'vue';

import useContext from '@/composables/useContext';
import { getContext } from '@/composables/usePdp';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useWholesaleAddToCart from '@/composables/useWholesaleAddToCart';
import useStock from '@/composables/useStock';
import useUser from '@/composables/useUser';

const addToCartQuantityKey: InjectionKey<Ref<Number>> = Symbol('addToCartQuantity');
const addToCartPackQuantityKey: InjectionKey<Ref<Number>> = Symbol('addToCartPackQuantity');
const addToCartDisabledKey: InjectionKey<Ref<Boolean>> = Symbol('addToCartDisabled');
const setAddToCartQuantityKey: InjectionKey<(newQuantity: Number) => void> = Symbol('setAddToCartQuantity');
const addToCartKey: InjectionKey<() => void> = Symbol('addToCart');
const addToCartQuantityErrorKey: InjectionKey<Ref<String>> = Symbol('addToCartQuantityError');

export default function () {
  const { route, cart, navigateToLoginPage, mitt } = useContext();
  const { websiteText } = useWebsiteTexts();
  const { selectedVariant, productData } = getContext();
  const { addToCart } = useWholesaleAddToCart();
  const { getTotalStockAvailable } = useStock();
  const { isAuthenticated } = useUser();

  const packQuantity = computed(() => {
    return selectedVariant.value?.fieldsJson?.PackedQuantity 
      || productData.value?.fieldsJson?.PackedQuantity
      || 1;
  });
  provide(addToCartPackQuantityKey, packQuantity);

  const quantity = ref(1);
  provide(addToCartQuantityKey, quantity);

  const addToCartError = ref('');

  const addToCartQuantityError = computed(() => {
    if (quantity.value > availableToPurchase.value) {
      return (
        websiteText('cart__available_stock', {
          amount: availableToPurchase.value,
        }).value
      );
    }

    if ((quantity.value || 1) % (packQuantity.value || 1) != 0) {
      return websiteText('cart__invalid_pack_quantity').value;
    }
    return '';
  });
  provide(addToCartQuantityErrorKey, addToCartQuantityError);

  const totalStockAvailable = computed(() => {
    return selectedVariant.value?.stock?.totalStockAvailable || 0;
  });

  const addToCartDisabled = computed(() => {
    return quantity.value % packQuantity.value != 0 ||
      getTotalStockAvailable({
        variant: selectedVariant.value,
        subtractQuantityFromCart: true,
      }) < quantity.value
      ? true
      : false;
  });
  provide(addToCartDisabledKey, addToCartDisabled);

  const addedToCartQuantity = computed(() => {
    return (cart.value?.rows || []).find((f) => f.articleNumber == selectedVariant.value?.articleNumber)?.quantity || 0;
  });

  const availableToPurchase = computed(() => {
    return (totalStockAvailable.value || 0) - (addedToCartQuantity.value || 0);
  });

  provide(setAddToCartQuantityKey, (newQuantity: number) => {
    addToCartError.value = '';
    quantity.value = newQuantity;
  });

  provide(addToCartKey, async () => {
    if (!isAuthenticated.value) {
      navigateToLoginPage(route.query);
      return;
    }

    try {
      const { row, error } = await addToCart({
        articleNumber: selectedVariant.value?.id,
        quantity: quantity.value,
      });

      if (error != 'NONE') {
        switch (error) {
          case 'NOT_ENOUGH_STOCK':
            addToCartError.value =
              websiteText('cart__available_stock', {
                amount: availableToPurchase.value || 0,
              }).value || 'cart__available_stock';
            break;
          case 'INVALID_PACK_QUANTITY':
            addToCartError.value = websiteText('cart__invalid_pack_quantity').value;
            break;
          default:
            addToCartError.value = websiteText('cart__add_to_cart_error').value;
            break;
        }

        return;
      }

      quantity.value = packQuantity.value;
      mitt.emit('added-to-cart', row?.id);
    } catch (err: any) {
      addToCartError.value = err;
    }
  });

  watch(
    () => selectedVariant.value,
    () => {
      addToCartError.value = '';
      quantity.value = packQuantity.value;
    },
    { immediate: true }
  );

  return {
    addToCartError,
  };
}

export function getCartActionsContext() {
  return {
    quantity: inject(addToCartQuantityKey),
    packQuantity: inject(addToCartPackQuantityKey),
    addToCartDisabled: inject(addToCartDisabledKey),
    addProductToCart: inject(addToCartKey, () => {}),
    changeQuantity: inject(setAddToCartQuantityKey),
    quantityError: inject(addToCartQuantityErrorKey),
  };
}
