import { ref } from 'vue';
import { sales } from '@distancify/drapejs-litium';
import * as kidsSales from '@/connectors/sales';
import * as user from '@/connectors/user';
import useContext from './useContext';
import useExecuteCommand from '@/composables/useExecuteCommand';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import { fetchPage, fetchPageBuilder } from '@drapejs/core';
import useRedirectUrl from '@/composables/useRedirectUrl';

import { setPrefferedCountryCookie, setPrefferedLanguageCookie } from '@/utils';

export default function () {
  const { navigateToLoginPage, route, channel, invoke } = useContext();
  const { executeCommand } = useExecuteCommand();
  const { websiteText } = useWebsiteTexts();
  const { buildRedirectUrl } = useRedirectUrl();

  const requestPending = ref(false);

  async function setQuantity({ rowId, quantity }) {
    try {
      requestPending.value = true;

      const response = await executeCommand(sales.setRowQuantity, {
        rowId,
        quantity,
      });

      const { row, error } = response;

      switch (error) {
        case 'NONE':
        case 'NOT_ENOUGH_STOCK':
        case 'INVALID_PACK_QUANTITY':
          return { row, error };
        default:
          throw websiteText('cart__add_to_cart_error').value || 'cart__add_to_cart_error';
      }
    } catch (err) {
      throw err;
    } finally {
      requestPending.value = false;
    }
  }

  async function addToCartMultiple(items) {
    try {
      requestPending.value = true;

      const response = await executeCommand(kidsSales.commands.addToCartMultiple, {
        items,
      });

      const { row, error } = response;

      switch (error) {
        case 'NONE':
        case 'NOT_ENOUGH_STOCK':
        case 'INVALID_PACK_QUANTITY':
          return { row, error };
        default:
          throw websiteText('cart__add_to_cart_error').value || 'cart__add_to_cart_error';
      }
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      requestPending.value = false;
    }
  }

  async function changeOrganization(organizationId) {
    try {
      requestPending.value = true;

      const response = await executeCommand(user.commands.changeOrganization, {
        organizationId,
      });

      const { cart, error } = response;
      switch (error) {
        case 'NONE':
          setPrefferedCountryCookie(cart.countryId);
          setPrefferedLanguageCookie(cart.locale);

          const request = {
            ...fetchPageBuilder(route.protocol, route.host, route.pathname, { ...route.query }, ''),
          };

          if (cart.channelId == channel.value.systemId) {
            invoke?.(fetchPage, request);
          } else {
            const redirectUrl = buildRedirectUrl({
              countryId: cart.countryId,
              languageId: cart.locale,
            });
            if (redirectUrl) {
              window.location.assign(redirectUrl);
            } else {
              invoke?.(fetchPage, request);
            }
          }
          break;
        case 'NOT_AUTHENTICATED':
          const query = {
            redirect: encodeURIComponent(route?.pathname || ''),
          };
          return navigateToLoginPage(query);
        case 'NO_ORGANIZATION_LINK':
          throw websiteText('cart__no_organization_link').value;
        case 'NO_ORGANIZATION_PURCHASING_COUNTRY':
          throw websiteText('cart__no_organization_purchasing_country').value;
        case 'NO_CHANNEL_SUPPORTING_PURCHASING_COUNTRY':
          throw websiteText('cart__no_channel_supporting_purchasing_country').value;
        default:
          throw websiteText('cart__change_organization_error').value;
      }
    } finally {
      requestPending.value = false;
    }
  }

  return {
    setQuantity,
    addToCartMultiple,
    changeOrganization,
    requestPending,
  };
}
